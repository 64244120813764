import store from '@/store'

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkPermi(value) {
	const permissions = store.getters && store.getters.permissions
	if (value && value instanceof Array && value.length > 0) {
		if (permissions.includes('*:*:*')) {
			return true
		}
		return permissions.some((permission) => {
			return value.includes(permission)
		})
	} else {
		console.error('请设置操作权限校验值')
		return false
	}
}

/**
 * 角色权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkRole(value) {
	if (value && value instanceof Array && value.length > 0) {
		const roles = store.getters && store.getters.roles
		const permissionRoles = value
		const super_admin = 'admin'

		const hasRole = roles.some((role) => {
			return super_admin === role || permissionRoles.includes(role)
		})

		if (!hasRole) {
			return false
		}
		return true
	} else {
		console.error(`need roles! Like checkRole="['admin','editor']"`)
		return false
	}
}
