import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { clearAuth, getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
	NProgress.start()
	if (getToken()) {
		to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
		/* has token*/
		if (store.getters?.roles?.length === 0) {
			// 判断当前用户是否已拉取完user_info信息
			store
				.dispatch('user/GetInfo')
				.then(({ roles, permissions }) => {
					store.dispatch('cache/UpdateAllTenant').catch()
					return store.dispatch('permission/GenerateRoutesAndDictionary', { roles, permissions })
				})
				.then((accessRoutes) => {
					// 根据权限生成可访问的路由表
					// 动态添加可访问路由表
					accessRoutes.forEach((route) => {
						router.addRoute(route)
					})
					next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
				})
				.catch((err) => {
					console.error(err)
					clearAuth()
					next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
				})
		} else {
			NProgress.done()
			next()
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
			NProgress.done()
			// window.location.href = `./login?redirectURL=${window.location.href}`
		}
	}
})

router.afterEach(() => {
	NProgress.done()
})
