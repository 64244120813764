import { constantRoutes } from '@/router'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'
import routes from '@/router/routes'

const permission = {
	namespaced: true,
	state: {
		routes: [],
		addRoutes: [],
		defaultRoutes: [],
		topbarRouters: [],
		sidebarRouters: []
	},
	mutations: {
		SET_ROUTES: (state, routes) => {
			state.addRoutes = routes
			state.routes = constantRoutes.concat(routes)
		},
		SET_DEFAULT_ROUTES: (state, routes) => {
			state.defaultRoutes = constantRoutes.concat(routes)
		},
		SET_TOPBAR_ROUTES: (state, routes) => {
			// 顶部导航菜单默认添加统计报表栏指向首页
			const index = [
				{
					path: 'index',
					meta: { title: '统计报表', icon: 'dashboard' }
				}
			]
			state.topbarRouters = routes.concat(index)
		},
		SET_SIDEBAR_ROUTERS: (state, routes) => {
			state.sidebarRouters = routes
		}
	},
	actions: {
		/**
		 * 生成路由
		 * @param context
		 * @param {{roles:string[],permissions:string[]}}payload
		 * @returns {Promise<unknown>}
		 */
		GenerateRoutesAndDictionary({ commit, rootGetters }, payload) {
			return new Promise((resolve) => {
				const sidebarRouteData = JSON.parse(JSON.stringify(routes))
				const rewriteRouteData = JSON.parse(JSON.stringify(routes))
				const sidebarRoutes = filterAsyncRouter(sidebarRouteData, false, false, rootGetters['user/permissionMap'])
				const rewriteRoutes = filterAsyncRouter(rewriteRouteData, false, true)
				rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })

				commit('SET_ROUTES', rewriteRoutes)
				commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
				commit('SET_DEFAULT_ROUTES', sidebarRoutes)
				commit('SET_TOPBAR_ROUTES', sidebarRoutes)

				resolve(rewriteRoutes)
			})
		},
		CheckPermission({ commit, rootGetters }, permission) {
			return new Promise((resolve, reject) => {
				const map = rootGetters['user/permissionMap']
				if (map[permission]) resolve()
				else reject()
			})
		}
	}
}

/**
 * 遍历后台传来的路由字符串，转换为组件对象
 * @param asyncRouterMap
 * @param lastRouter
 * @param type
 * @param permissionMap
 * @returns {*}
 */
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false, permissionMap = {}) {
	return asyncRouterMap.filter((route) => {
		if (type) {
			if (route.children) {
				route.children = filterChildren(route.children)
			}
		} else {
			// 权限校验
			// if (Boolean(route.meta?.permission)) {
			// 	if (!permissionMap[route.meta.permission]) {
			// 		return false
			// 	}
			// }
		}

		// 路由组件
		if (route.component) {
			// Layout ParentView 组件特殊处理
			if (route.component === 'Layout') {
				route.component = Layout
			} else if (route.component === 'ParentView') {
				route.component = ParentView
			} else if (route.component === 'InnerLink') {
				route.component = InnerLink
			} else {
				route.component = loadView(route.component)
			}
		}

		// 子路由
		if (route.children && route.children.length) {
			route.children = filterAsyncRouter(route.children, route, type, permissionMap)
		} else {
			delete route['children']
			delete route['redirect']
		}

		return true
	})
}

function filterChildren(childrenMap, lastRouter = false) {
	var children = []
	childrenMap.forEach((el, index) => {
		if (el.children && el.children.length) {
			if (el.component === 'ParentView') {
				el.children.forEach((c) => {
					c.path = el.path + '/' + c.path
					if (c.children && c.children.length) {
						children = children.concat(filterChildren(c.children, c))
						return
					}
					children.push(c)
				})
				return
			}
		}
		if (lastRouter) {
			el.path = lastRouter.path + '/' + el.path
		}
		children = children.concat(el)
	})
	return children
}

export const loadView = (view) => {
	// 路由懒加载
	return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
