import request from '@/utils/request'

function getRole(params) {
	return request({
		url: '/system/role/view',
		method: 'get',
		params
	})
}

/**
 * 不分页
 * @param data
 * @returns {*}
 */
function listRole(data) {
	return request({
		url: '/system/role/list',
		method: 'post',
		data
	})
}

/**
 * 分页
 * @param data
 * @returns {*}
 */
function queryRole(data) {
	return request({
		url: '/system/role/query',
		method: 'post',
		data
	})
}

function deleteRole(params) {
	return request({
		url: '/system/role/delete',
		method: 'get',
		params
	})
}

function saveOrUpdateRole(data) {
	return request({
		url: '/system/role/saveOrUpdate',
		method: 'post',
		data
	})
}

function validOrInValidRole(id, k = '') {
	return request({
		url: `/system/role/${k.toLowerCase()}`,
		method: 'get',
		params: { id }
	})
}

function getRolePermission(roleId) {
	return request({
		url: '/system/role/permission/list',
		method: 'get',
		params: { roleId }
	})
}

function saveOrUpdateRolePermission(data) {
	return request({
		url: '/system/role/permission/saveOrUpdate',
		method: 'post',
		data
	})
}

function getCurrentUserPermission() {
	return request({
		url: '/system/role/permission/granted',
		method: 'get'
	})
}

function batchDeleteUserPermission(userIds, roleIds) {
	return request({
		url: '/system/user/role/delete/all',
		method: 'get',
		data: { userIds, roleIds }
	})
}

function batchSaveOrUpdateUserPermission(userIds, roleIds) {
	return request({
		url: '/system/user/role/saveOrUpdate',
		method: 'get',
		data: { userIds, roleIds }
	})
}

function treePermission(clientId) {
	return request.get(`/system/menu/list/${clientId}`)
}

/**
 * @param {System.GrantUserRoleParams} params
 * @returns {Promise<AxiosResponse<any>>}
 */
function grantUserRoles(params) {
	return request.post('/system/user/role/auth', params)
}

function getUserRoles(userId) {
	return request.get(`/system/user/role/byUserId/${userId}`)
}

function getRoleUsers(roleId) {
	return request.get(`/system/user/role/byRoleId/${roleId}`)
}

function getUsersByRoleId(params) {
	return request.post(`/system/user/role/query`, params)
}

export {
	getRole,
	listRole,
	queryRole,
	deleteRole,
	saveOrUpdateRole,
	getRolePermission,
	validOrInValidRole,
	getCurrentUserPermission,
	batchDeleteUserPermission,
	saveOrUpdateRolePermission,
	batchSaveOrUpdateUserPermission,
	treePermission,
	grantUserRoles,
	getUserRoles,
	getRoleUsers,
	getUsersByRoleId
}
