import { login, logout, smsLogin } from '@/api/auth'
import { _iv, _key, getToken, removeToken, setToken, setUser } from '@/utils/auth'
import Crypto from 'crypto'
import { getUserRoles } from '@/api/user/role'
import { my } from '@/api/user'

const user = {
	namespaced: true,
	state: {
		token: getToken(),
		name: '',
		avatar: '',
		info: {},
		roles: [],
		permissions: []
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_EXPIRES_IN: (state, time) => {
			state.expires_in = time
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_INFO: (state, info) => {
			state.info = info
		}
	},

	actions: {
		/**
		 * 用户名密码登录
		 * @param commit
		 * @param dispatch
		 * @param userInfo
		 * @returns {Promise<unknown>}
		 */
		Login({ commit, dispatch }, userInfo) {
			const loginNo = userInfo.loginNo.trim()
			const password = userInfo.password
			return new Promise((resolve, reject) => {
				const md5 = Crypto.createHash('md5')
				md5.update(password)
				login(loginNo, md5.digest('hex'), userInfo.source)
					.then((res) => {
						const data = res.data
						const cipher = Crypto.createCipheriv('aes-128-cbc', _key, _iv)
						const userCrypto = cipher.update(JSON.stringify(data), 'utf8', 'hex') + cipher.final('hex')
						setUser(userCrypto)
						setToken(data.token)
						commit('SET_INFO', data)
						commit('SET_TOKEN', data.token)
						dispatch('dictionary/SetDictionary')
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 手机号登录
		 * @param commit
		 * @param userInfo
		 * @returns {Promise<unknown>}
		 */
		SmsLogin({ commit }, userInfo) {
			const mobile = userInfo.mobile.trim()
			const captcha = userInfo.captcha
			return new Promise((resolve, reject) => {
				smsLogin(mobile, captcha)
					.then((res) => {
						let data = res.data
						setToken(data.access_token)
						commit('SET_TOKEN', data.access_token)
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 获取用户信息
		 * @param commit
		 * @returns {Promise<unknown>}
		 */
		async GetInfo({ commit }) {
			const { data: user } = await my()
			commit('SET_PERMISSIONS', ['*:*:*'])
			commit('SET_INFO', user)
			commit('SET_NAME', user.name)
			commit('SET_AVATAR', user.avatar || user.tenantLogo)
			return Promise.all([
				getUserRoles(user.id).then((res) => {
					const roles = Array.from(res.data)
					commit('SET_ROLES', roles.length ? roles : ['admin'])
					return roles
				})
			]).then(([roles, permissions]) => {
				return {
					info: user,
					roles,
					permissions
				}
			})
		},

		/**
		 * 退出系统
		 * @param commit
		 * @param state
		 * @returns {Promise<unknown>}
		 * @constructor
		 */
		LogOut({ commit, state }) {
			return new Promise((resolve, reject) => {
				logout(state.token)
					.then(() => {
						commit('SET_TOKEN', '')
						commit('SET_ROLES', [])
						commit('SET_PERMISSIONS', [])
						removeToken()
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * 前端 登出
		 * @param commit
		 * @returns {Promise<unknown>}
		 * @constructor
		 */
		FedLogOut({ commit }) {
			return new Promise((resolve) => {
				commit('SET_TOKEN', '')
				removeToken()
				resolve()
			})
		}
	},
	getters: {
		permissionMap(state) {
			return Object.fromEntries(Array.from(state.permissions).map((v) => [v, true]))
		}
	}
}
export default user
